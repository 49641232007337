import style from "../style.module.css";

function Item({ nome, descricao, preco }){
 const isDisponivel = preco === 'Produto Indisponivel'? false: true


  return (
    <div className={style.item}>
      <div className={style.itemContent}>
      <div className={style.nomeItemDescricao}>
        <p className={style.nomeItem}>{nome}</p>
      <p className={style.descricao}>{descricao}</p>
      </div>
      <div className={style.precoConteiner}>
        <p className={isDisponivel? style.sifrao : style.nulo }>R$</p>
        <p className={isDisponivel? style.preco : style.indisponivel}>{preco}</p>
      </div>
      </div>
    </div>
  );
}

export default Item;
