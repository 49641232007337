
const produtos = [
    {
      title: "gourmet",
      availableSizes: ["unico"],
      produtos: [
        {
          nome: "Fabuloso",
          description:
            "Pão Brioche, picles, alface, tomate, molho especial, 2 hamburguers, cheddar, bacon.",
          prices: { unico: 29.0 },
        },
        {
          nome: "Maxximus",
          description:
            "Pão Brioche, molho especial, 2 hamburguers, cheddar, bacon, cebola caramelizada.",
          prices: { unico: 29.0 },
        },
        {
          nome: "Xerife",
          description:
            "Pão Brioche, picles, alface, tomate, molho especial, 2 hamburguers, provolone, bacon, cebola caramelizada.",
          prices: { unico: 29.0 },
        },
        {
          nome: "Invicto",
          description:
            "Pão Brioche, picles, alface, tomate, molho especial, 2 hamburguers, mussarela, lombo, cebola.",
          prices: { unico: 29.0 },
        },
        {
          nome: "Triplo X",
          description:
            "Pão Brioche, molho especial, 3 hamburguers, 3 mussarelas, 3 provolones.",
          prices: { unico: 31.0 },
        },
        {
          nome: "Steak Barbecue",
          description:
            "Pão Brioche, molho especial, picles, alface, tomate, 1 hamburguer, cheddar, mussarela.",
          prices: { unico: 26.0 },
        },
        {
          nome: "Mega",
          description:
            "Pão Brioche, molho especial, picles, alface, tomate, 1 hamburguer, cheddar.",
          prices: { unico: 20.0 },
        },
        {
          nome: "Texas",
          description: "Pão Brioche, molho especial, 1 hamburguer, mussarela.",
          prices: { unico: 20.0 },
        },
      ],
    },

    {
      title: "prensados",
      availableSizes: ["unico"],
      produtos: [
        {
          nome: "Cachorro Q. Simples",
          description: "Pão, alface, tomate, batata, 2 salsichas.",
          prices: { unico: 13.0 },
        },
        {
          nome: "Cachorro Q. Especial",
          description: "Pão, alface, tomate, batata, 1 salsicha, hamburguer.",
          prices: { unico: 16.0 },
        },
        {
          nome: "Cachorro Q. Duplo",
          description: "Pão, alface, tomate, batata, 2 salsichas, hamburguer.",
          prices: { unico: 17.0 },
        },
        {
          nome: "Cachorro Q. Frango",
          description:
            "Pão, alface, tomate, batata, 1 salsicha, hamburguer, frango.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Cachorro Q. Bacon",
          description:
            "Pão, alface, tomate, batata, 1 salsicha, hamburguer, bacon",
          prices: { unico: 20.0 },
        },
        {
          nome: "Cachorro Q. Caiuá",
          description:
            "Pão, alface, tomate, batata, 2 salsichas, hamburguer, calabresa, bacon.",
          prices: { unico: 21.0 },
        },
        {
          nome: "Cachorro Q. Calabresa",
          description:
            "Pão, alface, tomate, batata, 1 salsicha, hamburguer, calabresa.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Cachorro Q. Frango Catupiry",
          description:
            "Pão, alface, tomate, batata, 1 salsicha, hamburguer, frango, catupiry.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Cachorro Q. Frango Bacon",
          description:
            "Pão, alface, tomate, batata, 1 salsicha, hamburguer, frango, bacon.",
          prices: { unico: 21.0 },
        },
        {
          nome: "Cachorro Q. Frango Cheddar",
          description:
            "Pão, alface, tomate, batata, 1 salsicha, hamburguer, frango, cheddar.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Caiuá Lombo Cheddar",
          description:
            "Pão, alface, tomate, batata, 2 salsichas, 2 ovos, lombo, calabresa, cheddar.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Caiuá Maromba",
          description:
            "Pão, alface, tomate, batata, 3 ovos, hamburguer, frango.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Caiuá Fominha",
          description:
            "Pão, alface, tomate, batata, 2 salsichas, 2 ovos, calabresa, frango, catupiry.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Caiuá Zoião",
          description:
            "Pão, alface, tomate, batata, 3 salsichas, 3 ovos, 2 hamburguers.",
          prices: { unico: 21.0 },
        },
        {
          nome: "Caiuá Egg",
          description: "Pão, alface, tomate, batata, 2 salsichas, 2 ovos.",
          prices: { unico: 16.0 },
        },
        {
          nome: "Caiuá Calabresa",
          description:
            "Pão, alface, tomate, batata, 2 salsichas, 2 ovos, calabresa.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Caiuá Frango Catupiry",
          description:
            "Pão, alface, tomate, batata, 2 salsichas, 2 ovos, frango, catupiry.",
          prices: { unico: 19.0 },
        },
        {
          nome: "Caiuá Frango Cheddar",
          description:
            "Pão, alface, tomate, batata, 2 salsichas, 2 ovos, frango, cheddar.",
          prices: { unico: 19.0 },
        },
        {
          nome: "X Salada",
          description:
            "Pão, alface, tomate, batata, hamburguer, presunto, mussarela.",
          prices: { unico: 18.0 },
        },
        {
          nome: "X Salada Duplo",
          description:
            "Pão, alface, tomate, batata, 2 hamburguers, 2 presuntos, 2 mussarelas.",
          prices: { unico: 21.0 },
        },
        {
          nome: "X Egg",
          description:
            "Pão, alface, tomate, batata, hamburguer, presunto, mussarela, ovo.",
          prices: { unico: 19.0 },
        },
        {
          nome: "X Bacon",
          description:
            "Pão, alface, tomate, batata, hamburguer, presunto, mussarela, bacon.",
          prices: { unico: 22.0 },
        },
        {
          nome: "X Calabresa",
          description:
            "Pão, alface, tomate, batata, hamburguer, presunto, mussarela, calabresa.",
          prices: { unico: 19.0 },
        },
        {
          nome: "X Burguer",
          description: "Pão, batata, hamburguer, presunto, mussarela.",
          prices: { unico: 17.0 },
        },
        {
          nome: "X Vira Lata",
          description:
            "Pão, alface, tomate, batata, 2 salsichas, ovo, presunto, mussarela.",
          prices: { unico: 16.0 },
        },
        {
          nome: "X Caiuá",
          description:
            "Pão, alface, tomate, batata, hamburguer, bacon, calabresa, 2 salsichas, 2 presunto, 2 mussarela.",
          prices: { unico: 24.0 },
        },
        {
          nome: "X Baconlesa",
          description:
            "Pão, alface, tomate, batata, hamburguer, bacon, calabresa, presunto, mussarela.",
          prices: { unico: 24.0 },
        },
        {
          nome: "X Frangolesa",
          description:
            "Pão, alface, tomate, batata, hamburguer, frango, calabresa, presunto, mussarela.",
          prices: { unico: 24.0 },
        },
        {
          nome: "X Frango com Bacon",
          description:
            "Pão, alface, tomate, batata, hamburguer, frango, bacon, presunto, mussarela.",
          prices: { unico: 24.0 },
        },
        {
          nome: "X Frango Catupiry",
          description:
            "Pão, alface, tomate, batata, hamburguer, frango, catupiry, presunto, mussarela.",
          prices: { unico: 21.0 },
        },
        {
          nome: "X Frango",
          description:
            "Pão, alface, tomate, batata, hamburguer, frango, presunto, mussarela.",
          prices: { unico: 19.0 },
        },
        {
          nome: "X Tudo",
          description:
            "Pão, alface, tomate, batata, hamburguer, frango, bacon, calabresa, salsicha, ovo, presunto, mussarela.",
          prices: { unico: 27.0 },
        },
        {
          nome: "X Alcatra",
          description:
            "Pão, alface, tomate, batata, hamburguer, alcatra, 2 presunto, 2 mussarela.",
          prices: { unico: 29.0 },
        },
        {
          nome: "X Galinha",
          description:
            "Pão, alface, tomate, batata, frango, presunto, mussarela.",
          prices: { unico: 16.5 },
        },
        {
          nome: "Dog Calabresa",
          description: "Pão, alface, tomate, batata, 2 salsichas, calabresa.",
          prices: { unico: 16.0 },
        },
        {
          nome: "Dallas",
          description: "Pão, alface, tomate, batata, 2 salsichas, carne moida.",
          prices: { unico: 17.0 },
        },
        {
          nome: "Mensalão",
          description:
            "Pão, alface, tomate, milho, batata, 2 salsichas, carne moida, ovo.",
          prices: { unico: 17.0 },
        },
        {
          nome: "Dog Frango",
          description: "Pão, alface, tomate, batata, 2 salsichas, frango.",
          prices: { unico: 16.0 },
        },
      ],
    },

    {
      title: "porções",
      availableSizes: ["meia", "inteira"],
      produtos: [
        {
          nome: "Alcatra com Fritas",
          description:
            "Alcatra acebolada ao molho Mostarda e Shoyo acompanhado de batata frita e torrada",
          prices: { meia: 55.0, inteira: 88.0 },
        },
        {
          nome: "Calabresa com Torrada",
          description: "Calabresa acebolada acompanhada de torrada",
          prices: { meia: 55.0, inteira: 88.0 },
        },
        {
          nome: "Frango à Passarinho",
          description: "Frango a passarinho molho Mostarda e temperos",
          prices: { meia: 30.0, inteira: 50.0 },
        },
        {
          nome: "Costelinha de Porco",
          description:
            "Costelinha de porco ao molho Mostarda e temperos acompanhado de batata frita",
          prices: { meia: 30.0, inteira: 50.0 },
        },
        {
          nome: "Batata Frita",
          description:
            "Batata ao corte 7mm fritas, crocante por fora e macia por dentro.",
          prices: { meia: 22.0, inteira: 30.0 },
        },
        {
          nome: "Batata Mussarela e Bacon",
          description:
            "Batata ao corte 7mm fritas, acompanhada de queijo mussarela derretido e bacon.",
          prices: { meia: 20.0, inteira: 30.0 },
        },
        {
          nome: "Batata Cheddar e Bacon",
          description:
            "Batata ao corte 7mm fritas, acompanhada de queijo cheddar derretido e bacon.",
          prices: { meia: 20.0, inteira: 30.0 },
        },
        {
          nome: "Bolinhas de Queijo",
          description:
            "Bolinhas de queijo fritas, crocante por fora e macio por dentro",
          prices: { meia: 16.0, inteira: 30.0 },
        },
        {
          nome: "Bolinhas de Carne Seca",
          description:
            "Bolinhas de Carne seca fritas, crocante por fora e macio por dentro",
          prices: { meia: 16.0, inteira: 30.0 },
        },
      ],
    },

    {
      title: "pizzas",
      availableSizes: ["grande", "média", "brotinho"],
      produtos: [
        {
          nome: "Caiuá",
          description:
            "Molho, calabresa, presunto, palmito, mussarela, catupiry, tomate, oregano",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },

        {
          nome: "Gorduchinha",
          description:
            "Molho, calabresa, frango, mussarela, bacon, cebola, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Paranacity",
          description:
            "Molho, frango, milho, mussarela, bacon, cebola, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "4 Queijos",
          description:
            "Molho, catupiry, cheddar, mussarela, provolone, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Marguerita",
          description: "Molho,mussarela, manjegicão, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Bacon",
          description: "Molho, mussarela, bacon, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Frango Catupiry",
          description:
            "Molho, frango, milho, mussarela, catupiry, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Toscana",
          description:
            "Molho, calabresa, lombo, mussarela, bacon, cebola, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Calabresa",
          description: "Molho, calabresa, mussarela, cebola, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Portuguesa",
          description:
            "Molho, calabresa, presunto, milho, ovo, mussarela, cebola, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Crocante Bacon",
          description:
            "Molho, bacon, mussarela, catupiry, oregano, batata palha.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Frango",
          description: "Molho, frango, milho, mussarela, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Frango Cheddar Bacon",
          description:
            "Molho, frango, milho, mussarela, cheddar, bacon, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Bauru",
          description: "Molho, presunto, mussarela, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Cruzeiro do Sul",
          description: "Molho, frango, milho, ovo, mussarela, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Atum e Cebola",
          description: "Molho, atum, cebola, mussarela, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Lombo",
          description: "Molho, lombo, cebola, mussarela, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Mussarela",
          description: "Molho, mussarela, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Bolonhesa",
          description: "Molho, carne moida, mussarela, oregano, batata palha.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Francesa",
          description:
            "Molho, presunto, palmito, mussarela, oregano, batata palha.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Palmito",
          description: "Molho, palmito, mussarela, catupiry, tomate, oregano.",
          prices: { grande: 55.0, média: 45.0, brotinho: 30.0 },
        },
        {
          nome: "Animal",
          description:
            "Molho, calabresa, frango, milho, mussarela, catupiry, cheddar, oregano.",
          prices: { grande: 60.0, média: 50.0, brotinho: 35.0 },
        },
        {
          nome: "Strogonoff de Carne",
          description:
            "Molho,strogonoff de carne, mussarela, oregano, batata palha.",
          prices: { grande: 60.0, média: 50.0, brotinho: 35.0 },
        },
        {
          nome: "Strogonoff de Frango",
          description:
            "Molho,strogonoff de Frango, mussarela, oregano, batata palha.",
          prices: { grande: 60.0, média: 50.0, brotinho: 35.0 },
        },
      ],
    },

    {
      title: "esfihas",
      availableSizes: ["unico"],
      produtos: [
        {
          nome: "Esfiha de Carne",
          description: "massa de esfiha, carne moida, tomate, cebola.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Carne e Bacon",
          description: "massa de esfiha, carne moida, tomate, cebola, bacon.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Carne Queijo",
          description: "massa de esfiha, carne moida, tomate, cebola, queijo.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Calabresa com Cebola",
          description: "massa de esfiha, molho de tomate, calabresa, cebola.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Frango",
          description: "massa de esfiha, frango desfiado ao molho.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Frango Catupiry",
          description: "massa de esfiha, frango defiado ao molho, catupiry.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Frango Cheddar",
          description: "massa de esfiha, frango defiado ao molho, cheddar.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Cachorro Quente",
          description:
            "massa de esfiha, molho de tomate, salsicha, catupiry, batata palha.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de Milho com Bacon",
          description: "massa de esfiha, milho, mussarela, bacon.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de 2 Queijos",
          description: "massa de esfiha, catupiry, mussarela, oregano.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Esfiha de 4 Queijos",
          description:
            "massa de esfiha, catupiry, cheddar, mussarela, oregano.",
          prices: { unico: 6.0 },
        },
        {
          nome: "Strogonoff de Carne",
          description:
            "massa de esfiha, strogonoff de carne, mussarela, oregano, batata palha.",
          prices: { unico: 8.0 },
        },
        {
          nome: "Strogonoff de Frango",
          description:
            "massa de esfiha, strogonoff de frango, mussarela, oregano, batata palha.",
          prices: { unico: 8.0 },
        },
      ],
    },
    {
      title: "bebidas",
      availableSizes: ["lata", "600ml", "1ltr", "2ltrs"],
      produtos: [
        {
          nome: "Coca cola",
          prices: { lata: 5.0, "600ml": 8.0, "1ltr": 10.0, "2ltrs": 15.0 },
        },
        {
          nome: "Graraná",
          prices: { lata: 5.0, "600ml": 7.0, "1ltr": 9.0, "2ltrs": 12.0 },
        },
        {
          nome: "Funada",
          prices: { "600ml": 7.0, "2ltrs": 12.0 },
        },
        {
          nome: "Fanta",
          prices: { lata: 5.0, "600ml": 7.0, "1ltr": 9.0, "2ltrs": 12.0 },
        },
        {
          nome: "Sprite",
          prices: { lata: 5.0, "600ml": 7.0, "1ltr": 9.0, "2ltrs": 12.0 },
        },
        {
          nome: "Soda",
          prices: { lata: 5.0, "600ml": 7.0, "1ltr": 9.0, "2ltrs": 12.0 },
        },
        {
          nome: "Ouro Branco",
          prices: { lata: 5.0, "600ml": 7.0, "1ltr": 9.0, "2ltrs": 12.0 },
        },
      ],
    },
  ] ;


export default produtos;