import { useState } from 'react';
import style from '../style.module.css';
import Item from '../items/items.component';

function Sections({ title, items, id, options}) {
  const [selectOption, setSelectOption] = useState(options.length > 1 ?  options[0]: null);
  const handleOptionChange = (event) =>{
    setSelectOption(event.target.value);
  }
  return (
    <section id={id}>
      <p className={style.nomeSection}>{title}

      {
        options.length > 1 && (
          <select 
              id={`${title}-size`}
              value={selectOption}
              onChange={handleOptionChange}
              className={style.selectOption}>

           {options.map((size) =>(
            <option key={size} value={size}>
              {size.charAt(0).toUpperCase() + size.slice(1)}
            </option>
           ))}
          </select>
         )
      }

      <a href='#categories'>
        <img src={`${process.env.PUBLIC_URL}/images/up.png`} className={style.up} alt='up-set'/>
      </a>
      </p>
      <div>
        {
        items.map((item)=>{

          const priceForSelectedSize = options.length > 1 ? item.prices[selectOption]: Object.values(item.prices)[0];
          return(
            <Item 
            nome={item.nome} 
            descricao={item.description} 
            preco={
              priceForSelectedSize !== undefined
              ? priceForSelectedSize.toFixed(2):"Produto Indisponivel"}/>

          );
        })
        
        
        
        }</div>
    </section>
  );
}

export default Sections;