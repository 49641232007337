import "./App.css";
import CategoriesContainer from "./components/sections/categories/categories.container";
import HeaderSection from "./components/sections/header.section";
import WhatsappIcon from "./components/items/whatsapp.icon";
import Sections from "./components/sections/sections.component";

function App({produtos}) {
  const sections = produtos; 
  return (
    <div>
      <HeaderSection />
      <CategoriesContainer />
      {sections.map((section) => (
        <Sections
          id={section.title}
          title={section.title.toUpperCase()}
          items={section.produtos}
          options={section.availableSizes}
        />
      ))}
      <WhatsappIcon />
    </div>
  );
}

export default App;
